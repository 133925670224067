import { useState, useEffect } from "react";
import moment from "moment";
import Banner from "./Banner/Banner";
import Games from "./Games/Games";
import LiveGames from "./LiveGames/LiveGames";
import AboutUs from "./AboutUs/AboutUs";
// import PoweredBy from "./PoweredBy/PoweredBy";
// import WheelPopup from "./WheelPopup/WheelPopup";
import "./Main.css";

function Main({  account, wheelGameTime, user }) {
  const day = moment().utc().format("YYYY-MM-DD");
  const hours = Boolean(wheelGameTime)
    ? Math.abs(
        moment(wheelGameTime + "+00:00", "YYYY-MM-DDTHH:mm:ssZ").diff(
          moment(),
          "hours"
        )
      )
    : 100;
  // eslint-disable-next-line no-unused-vars
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isWheelAvailable, setWheelAvailable] = useState(
    localStorage.getItem("isWheelAvailable") !== null
      ? JSON.parse(localStorage.getItem("isWheelAvailable"))
      : undefined
  );
  const [popupTime, setPopupTime] = useState(
    localStorage.getItem("popupTime") !== null
      ? JSON.parse(localStorage.getItem("popupTime"))
      : ""
  );
  const [isNewUser, setNewUser] = useState(
    localStorage.getItem("isNewUser") !== null
      ? JSON.parse(localStorage.getItem("isNewUser"))
      : true
  );



  useEffect(() => {
    if (user) return;

    if (isNewUser) {
      openWheelPopup();
      setNewUser(false);
      localStorage.setItem("isNewUser", false);
    } else {
      if (Boolean(popupTime)) {
        const days = Math.abs(
          moment(popupTime, "YYYY-MM-DD").diff(
            moment(day, "YYYY-MM-DD"),
            "days"
          )
        );

        if (days > 7) openWheelPopup();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user) return;

    if (day !== popupTime) {
      setWheelAvailable(hours >= 24);
      localStorage.setItem("isWheelAvailable", hours >= 24);
    }
  }, [user, hours, popupTime, day]);

  useEffect(() => {
    if (account && isWheelAvailable) openWheelPopup();
  }, [account, isWheelAvailable]);

  const openWheelPopup = () => {
    const day = moment().utc().format("YYYY-MM-DD");
    setPopupOpen(true);
    setPopupTime(day);
    localStorage.setItem("popupTime", JSON.stringify(day));
  };

  // const closeWheelPopup = () => {
  //   setPopupOpen(false);
  //   setWheelAvailable(false);
  //   localStorage.setItem("isWheelAvailable", false);
  // };

  return (
    <main className="main">
      <Banner />
      <Games />
      <LiveGames {...{account }} />
      {/* <AboutUs /> */}
      {/* <PoweredBy /> */}
      {/* <WheelPopup isOpen={isPopupOpen} onClose={closeWheelPopup} /> */}
    </main>
  );
}

export default Main;
