//games pictures
import coinFlip from "../images/main/coin-flip-game.png";
import megaDice from "../images/main/mega-dice-game.png";
import roulette from "../images/main/roulette-game-grey.png";
import limbo from "../images/main/limbo-game.png";
import crash from "../images/main/crash-game.png";
import lucky_sevens from "../images/main/lucky-sevens-game.png";
// import dragon from "../images/main/dragon-game.png";
import dragon from "../images/main/dragon-game-gray.png";
import shuttle from "../images/main/shuttle-game.png";
// import shuttle from "../images/main/shuttle-game-gray.png";

// import updown from "../images/main/updown-game.png"
import updown from "../images/main/updown-game-gray.png"

import lucky_wheel from "../images/main/wheel-game.png"


//wallets icons
import walletConnect from "../images/currency/wallet-connect.png";
import metamask from "../images/currency/metamask.png";
import coinbase from "../images/currency/coinbase.png";
import {
  METAMASK_CONNECTOR,
  WALLET_CONNECTOR,
  COINBASE_CONNECTOR,
  NETWORKS,
  DEFAULT_NETWORK,
  NETWORKS_ARRAY,
} from "./constants";
import { ethers } from "ethers";

export const gamesList = [
  {
    link: "/mega-dice",
    return: 100,
    title: "Mega Dice",
    text: ["Choose Over or Under, roll", <br />, "dice and claim your prize"],
    image: megaDice,
  },
  {
    link: "/coin-flip",
    return: 1000,
    title: "Coin Flip",
    text: ["Guess Heads or Tails", <br />, "to double your bet"],
    image: coinFlip,
  },
  {
    link: "/limbo",
    return: 100,
    title: "Limbo",
    text: ["Predict the multiplier", <br />, "and win"],
    image: limbo,
  },
  {
    link: "/crash",
    return: 100,
    title: "Crash",
    text: ["Cash out before the crash", <br />, "to score big"],
    image: crash,
  },
  {
    link: "/lucky-sevens",
    return: 100,
    title: "Lucky Sevens",
    text: ["Try your luck", <br />, "with three sevens"],
    image: lucky_sevens,
  },
  {
    link: "/shuttle",
    return: 100,
    title: "Shuttle",
    text: ["Fly through space", <br />, "bet and win"],
    image: shuttle,
  },
  {
    link: "/lucky-wheel",
    return: 100,
    title: "Lucky Wheel",
    text: ["Spin the wheel", <br />, "and receive the meal"],
    image: lucky_wheel,
  },
  {
    link: "/up-or-down",
    return: "coming soon",
    title: "Up or Down",
    text: ["Forecast the coin's path", <br />, "and multiply your stakes"],
    image: updown,
  },
  {
    link: "/dragon",
    return: "coming soon",
    title: "Dragon",
    text: ["Bet on the dragon", <br />, "for big wins"],
    image: dragon,
  },
  // {
  //   link: "/roulette",
  //   return: "coming soon",
  //   title: "Roulette",
  //   text: ["Try your luck in the", <br />, "most popular classic game"],
  //   image: roulette,
  // },

];

export const barabanSlicesList = [
  "3",
  "5",
  "1",
  "0.2",
  "0.1",
  "100",
  "0.1",
  "0.2",
  "1",
  "2",
  "3",
  "5",
  "1",
  "0.2",
  "0.1",
  "1 000",
  "0.1",
  "0.2",
  "1",
  "2",
];

export const walletsList = [
  {
    connector: "injected",
    icon: metamask,
    title: "Metamask",
    soon: false,
  },
  {
    connector: "walletConnect",
    icon: walletConnect,
    title: "Wallet Connect",
    soon: false,
  },
  {
    connector: "coinbaseWallet",
    icon: coinbase,
    title: "Coinbase",
    soon: false,
  },
];

export const truncateAddress = (address) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

export const toHexStr = (str) => {
  var result = "";
  for (var i = 0; i < str.length; i++) {
    result += str.charCodeAt(i).toString(16);
  }
  return result;
};

export function prettyValue(n, decimals) {
  n = n instanceof ethers.BigNumber ? n : ethers.BigNumber.from(n)
  let decimal = ethers.BigNumber.from(1).mul(ethers.BigNumber.from(10).pow(decimals))
  return n.lt(decimal)
    ? Number(Number(ethers.utils.formatUnits(n, decimals)).toFixed(5))
    : n.lt(decimal.mul(10))
      ? Number(Number(ethers.utils.formatUnits(n, decimals)).toFixed(4))
      : n.lt(decimal.mul(100))
        ? Number(Number(ethers.utils.formatUnits(n, decimals)).toFixed(3))
        : n.lt(decimal.mul(1000))
          ? Number(Number(ethers.utils.formatUnits(n, decimals)).toFixed(2))
          : Number(Number(ethers.utils.formatUnits(n, decimals)).toFixed(1));
}

export function strip(number) {
  return parseFloat(number.toPrecision(12));
}

export function getWallet(connector) {
  if (connector?.connector?.name === 'Metamask') return METAMASK_CONNECTOR;
  if (connector?.connector?.name === 'WalletConnectV2') return WALLET_CONNECTOR;
  if (connector?.connector?.name === 'CoinbaseWallet') return COINBASE_CONNECTOR;
  return "Unknown";
}

export function getNetworkApiNameFromChainId(chain_id) {
  return chain_id ? NETWORKS[chain_id.toString()].api_name : DEFAULT_NETWORK.api_name
}

export function findNetworkByApiName({ apiName }) {

  return NETWORKS_ARRAY.find(network => network.api_name === apiName);
};

export function isTokenAddressAvailable({ tokenAddress }) {
  return tokenAddress ? NETWORKS_ARRAY.some(network => network.token_address.toLowerCase() === tokenAddress.toLowerCase()) : true;
}