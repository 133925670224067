/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from "react";
// import { Link } from "react-router-dom";
import AnimatedNumber from "react-animated-number";
import { useEthers } from "@usedapp/core";
import { useIcons } from "../../assets/hooks/useIcons";
// import { truncateAddress } from "../../assets/utilis/utilis";
import {
  BURGER_BTN_ICON,
  DISCONNECT_ICON,
  ARROW_ICON,
  // ARROW_ICON,
  // DISCONNECT_ICON,
} from "../../assets/utilis/icons";
import "./Header.css";
import { prettyValue, truncateAddress } from "../../assets/utilis/utilis";
import { ethers } from "ethers";
import { NETWORKS, NETWORKS_ARRAY } from "../../assets/utilis/constants";

function Header({
  isLoggedIn,
  isMenuOpen,
  toggleMenu,
  onModalOpen,
  disconnect,
  user,
  isGamePlaying,
  tokenDecimals,
  sendToIframe,
  selectNetwork,
}) {
  const overlay = useRef();
  const { active, account, chainId } = useEthers();
  const { setCurrencyIconByChainId, setCurrencyTitleByChainId } = useIcons();
  const [drops, setDrops] = useState({
    user: false,
    network: false,
  })
  const [balance, setBalance] = useState(0);

  const [selectedNetwork, setSelectedNetwork] = useState(NETWORKS[`${chainId}`] ? NETWORKS[`${chainId}`] : null)

  useEffect(() => {
    setSelectedNetwork(NETWORKS[`${chainId}`] ? NETWORKS[`${chainId}`] : null)
  }, [chainId])

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current !== evt.target) {
        setDrops(prevValue => ({
          ...prevValue,
          user: false,
          network: false,
        }));
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  useEffect(() => {
    if (user && user.balance !== undefined) setBalance(user.balance);
  }, [user]);

  function prettyBalanceValue(n, title) {
    n = n ? ethers.utils.parseUnits(n.toFixed(tokenDecimals), tokenDecimals) : ethers.BigNumber.from(0)
    return prettyValue(n, tokenDecimals) + ` ${title}`
  }

  function logout() {
    disconnect();
    setDrops(prevValue => ({
      ...prevValue,
      user: false,
    }));
    sendToIframe("editData", { set: { "isExternalWalletConnected": false } })
  }

  return (
    // <header className="header">
    //   <div className="header__container">
    //     <button
    //       className="header__burger-button"
    //       type="button"
    //       aria-label="Open menu"
    //       onClick={!isMenuOpen ? toggleMenu : undefined}
    //     >
    //       {BURGER_BTN_ICON}
    //     </button>
    //   </div>
    // </header>
    <header className="header">
      <div className="header__container">
        <button
          className="header__burger-button"
          type="button"
          aria-label="Open menu"
          onClick={!isMenuOpen ? toggleMenu : undefined}
        >
          {BURGER_BTN_ICON}
        </button>
        <div></div>

        <div className={`header__info-block ${isGamePlaying ? 'header__info-block_disabled' : ''}`}>
          <div
            className={`header__demo-block ${user && active ? "header__demo-block_user" : ""
              }`}
          >
            {user && active && isLoggedIn && selectedNetwork ?
              <div className="header__demo-box">
                <img
                  className="header__currency-icon"
                  src={setCurrencyIconByChainId(chainId)}
                  alt="Network icon"
                />
                <AnimatedNumber
                  component="p"
                  value={Number(ethers.utils.formatUnits(balance, tokenDecimals))}
                  className="header__demo-text"
                  duration={200}
                  formatValue={(n) => prettyBalanceValue(n, setCurrencyTitleByChainId(chainId))}
                />
              </div>
              : null
            }
          </div>

          {
            !active || !isLoggedIn
              ?
              <div></div>
              :
              <div className="header__select-network-box">
                <button
                  className="header__current-network"
                  type="button"
                  onClick={() => setDrops(prevValue => ({
                    ...prevValue,
                    network: true,
                  }))}
                >
                  {selectedNetwork ?
                    <img className="header__current-network-logo" src={selectedNetwork.logo} alt={`${selectedNetwork.name} logo`}></img>
                    : null}
                  <p className="header__current-network-name">
                    {selectedNetwork ? selectedNetwork.name : 'Select network'}
                  </p>
                  <div
                    className={`header__arrow-icon ${drops.network ? "header__arrow-icon_reverse" : ""
                      }`}
                  >
                    {ARROW_ICON}
                  </div>
                </button>

                <div
                  className={`header__select-popup ${drops.network ? "header__select-popup_opened" : ""
                    }`}
                  ref={overlay}
                >
                  <div className="header__select-popup-list">
                    {NETWORKS_ARRAY.map((network, i) => (
                      <button className={`header__select-popup-list-item ${chainId && network.chain_id.toString() === chainId.toString() ? 'header__select-popup-list-item_selected' : ''}`}
                        onClick={() => {
                          selectNetwork({ chain_id: network.chain_id })
                        }}>
                        <img className="header__select-popup-list-item-icon" src={network.logo} alt={`${network.name} logo`}></img>
                        <p className="header__select-popup-list-item-text" >{network.name}</p>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
          }

          {!active || !isLoggedIn ? (
            <button
              className={`header__login-btn `}
              type="button"
              onClick={onModalOpen}
            >
              Connect
            </button>
          ) :
            <div className="header__box-with-drop">
              <button
                className="header__user-box"
                type="button"
                onClick={() => setDrops(prevValue => ({
                  ...prevValue,
                  user: true,
                }))}
              >
                <p className="header__user-text">
                  {account ? truncateAddress(account) : null}
                </p>
                <div
                  className={`header__arrow-icon ${drops.user ? "header__arrow-icon_reverse" : ""
                    }`}
                >
                  {ARROW_ICON}
                </div>
              </button>

              <div
                className={`header__select-popup ${drops.user ? "header__select-popup_opened" : ""
                  }`}
                ref={overlay}
              >
                <div className="header__select-popup-list">
                  <button
                    className="header__select-popup-list-item"
                    type="button"
                    onClick={logout}
                  >
                    <div className="header__select-popup-list-item-icon">{DISCONNECT_ICON}</div>
                    <p className="header__select-popup-list-item-text" >Disconnect</p>
                  </button>
                </div>

              </div>
            </div>
          }


        </div>
      </div>
    </header>
  );
}

export default Header;
