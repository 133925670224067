import { Link } from "react-router-dom";
import { gamesList } from "../../../assets/utilis/utilis";
import "./Games.css";

function Games() {
  return (
    <div className="games">
      <p className="games__title">Let's Play!</p>
      <ul className="games__list">
        {gamesList.map((game) => (
          <li
            className="games__item"
            key={game.title.toLowerCase().split(" ").join("-")}
          >
            <Link
              className={`games__link ${
                game.return === "coming soon" ? "games__link_disabled" : ""
              }`}
              to={game.link}
            >
              <div className="games__card">
                <p className="games__return">
                  {game.return === "coming soon"
                    ? game.return
                    : `X${game.return} return`}
                </p>
                <div className="games__text-block">
                  <p className="games__card-title">{game.title}</p>
                  <p className="games__card-text">{game.text}</p>
                </div>
                <img
                  className="games__image"
                  src={game.image}
                  alt={game.title}
                />
                <div
                  className={`games__back-ellipse games__back-ellipse_type_${game.title
                    .toLowerCase()
                    .split(" ")
                    .join("-")}`}
                />
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Games;
