import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useIcons } from "../../../assets/hooks/useIcons";
import { MEGA_DICE_GAME, COIN_FLIP_GAME, DEFAULT_DECIMALS, LIMBO_GAME, WHEEL_GAME } from "../../../assets/utilis/constants";
import "./LiveGames.css";
import mainApi from "../../../assets/api/MainApi";
import { findNetworkByApiName, isTokenAddressAvailable, prettyValue } from "../../../assets/utilis/utilis";
import { useEthers } from "@usedapp/core";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";

function LiveGames({ account }) {
  const { chainId } = useEthers();

  const { setGameIcon, setGameTitle, setCurrencyIconByChainId } = useIcons();
  const [timerValue, setTimerValue] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimerValue(timerValue + 15);
      clearInterval(timer);
    }, 15000);
  }, [timerValue, chainId]);

  const [games, setGames] = useState(null)
  const [isPreloaderVisible, setPreloaderVisible] = useState(true)

  useEffect(() => {
    mainApi.getAllGames({ limit: 25, gameTypes: `${COIN_FLIP_GAME},${MEGA_DICE_GAME},${LIMBO_GAME},${WHEEL_GAME}`, chainId: chainId, isAll: true })
      .then((res) => {
        // console.log(res)
        const games = res.data.map(
          (game) =>
          (game = {
            ...game,
            sent_value: prettyValue(
              Number(Number(game.sent_value).toFixed(0)), DEFAULT_DECIMALS
            ),
            payout_amount: prettyValue(
              Number(Number(game.payout_amount).toFixed(0)), DEFAULT_DECIMALS
            ),
          })
        );
        // console.log("all game sessions:", games);
        setGames(games)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setPreloaderVisible(false)
      })
  }, [timerValue, chainId])

  return (
    <div className="live">
      <p className="live__title">Live Games</p>
      <div className="live__table-container">
        <table className="live__table">
          <thead className="live__table-head">
            <tr className="live__table-tr-head">
              <th
                className="live__table-th-head live__table-th-head_type_game"
                colSpan="1"
              >
                <p className="live__table-title">Game</p>
              </th>
              <th
                className="live__table-th-head live__table-th-head_type_player"
                colSpan="1"
              >
                <p className="live__table-title">Player</p>
              </th>
              <th
                className="live__table-th-head live__table-th-head_type_transaction"
                colSpan="1"
              >
                <p className="live__table-title">Transaction</p>
              </th>
              <th
                className="live__table-th-head live__table-th-head_type_bet"
                colSpan="1"
              >
                <p className="live__table-title">Bet</p>
              </th>
              <th
                className="live__table-th-head live__table-th-head_type_payout"
                colSpan="1"
              >
                <p className="live__table-title">Payout</p>
              </th>
            </tr>
          </thead>
          <tbody className="live__table-body">
            {isPreloaderVisible ?
              <tr className="live__table-tr-body">
                <td className="live__table-body-preloader" colSpan="5">
                  <div className="live__table-body-preloader-box">
                    <MiniPreloader isLinkColor={true} />
                  </div>
                </td>
              </tr>
              :
              games && games.length > 0 ? (
                games.map((game, i) => {
                  if (!isTokenAddressAvailable({ tokenAddress: game.token })) return null
                  return (
                    <tr
                      className={`live__table-tr-body ${Number(moment().format('x')) - Number(moment(game.utc_date + "+00:00", "YYYY-MM-DDTHH:mm:ssZ").format('x')) < 3000 ? account && account === game.address ? 'live__table-tr-body_new-current-user' : 'live__table-tr-body_new' : ''}`}
                      key={`live__table-body-tr${i}`}
                    >
                      <td className="live__table-td-body live__table-td-body_type_game">
                        <Link to={`/${game.game_type === MEGA_DICE_GAME ?
                          'mega-dice'
                          :
                          game.game_type === COIN_FLIP_GAME ?
                            'coin-flip'
                            :
                            game.game_type === WHEEL_GAME ?
                              'lucky-wheel'
                              :
                              game.game_type
                          }`} className="live__table-cell">
                          <div className="live__game-icon">
                            {setGameIcon(game.game_type)}
                          </div>
                          <p className="live__game-text">
                            {setGameTitle(game.game_type)}
                          </p>
                        </Link>
                      </td>
                      <td className="live__table-td-body live__table-td-body_type_player">
                        <a
                          className="live__id-text"
                          href={`https://${findNetworkByApiName({ apiName: game.network }).scan_domain}/address/${game.address}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {game.address.length > 10
                            ? `${game.address.slice(0, 4)}...${game.address.slice(
                              -6
                            )}`
                            : game.address}
                        </a>
                      </td>
                      <td className="live__table-td-body live__table-td-body_type_transaction">
                        <a
                          className="live__id-text"
                          href={`https://${findNetworkByApiName({ apiName: game.network }).scan_domain}/tx/${game.transaction_hash}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {game.transaction_hash.length > 10
                            ? `${game.transaction_hash.slice(
                              0,
                              4
                            )}...${game.transaction_hash.slice(-6)}`
                            : game.transaction_hash}
                        </a>
                      </td>
                      <td className="live__table-td-body live__table-td-body_type_bet">
                        <div className="live__table-cell live__table-cell_type_bet">
                          <p className="live__money-text">
                            {game.sent_value}
                          </p>
                          <img
                            className="live__currency-icon"
                            src={setCurrencyIconByChainId(game.network)}
                            alt={`${game.network} network icon`}
                          />
                        </div>
                      </td>
                      <td className="live__table-td-body live__table-td-body_type_payout">
                        <div className="live__table-cell live__table-cell_type_payout">
                          <p
                            className={`live__money-text ${Number(game.payout_amount) !== 0
                              ? "live__money-text_type_green"
                              : ""
                              }`}
                          >
                            {game.payout_amount}
                          </p>
                          <img
                            className="live__currency-icon"
                            src={setCurrencyIconByChainId(game.network)}
                            alt={`${game.network} network icon`}
                          />
                        </div>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr className="live__table-tr-body live__table-tr-body_type_not-found">
                  <td className="live__not-found" colSpan="5">
                    Nothing was found
                  </td>
                </tr>
              )}

          </tbody>
        </table>
      </div>
    </div >
  );
}

export default LiveGames;