import { useState, useEffect } from "react";
import { useEthers } from "@usedapp/core";
import { Link, NavLink } from "react-router-dom";
import AnimatedNumber from "react-animated-number";

import { useIcons } from "../../assets/hooks/useIcons";
import useWindowSize from "../../assets/hooks/useWindowSize";
import usePreventScroll from "../../assets/hooks/usePreventScroll";
import {
  LOGO_ICON,
  CLOSE_ICON,
  COIN_FLIP_ICON,
  MEGA_DICE_ICON,
  ROULETTE_ICON,
  REF_SYSTEM_ICON,
  DOCS_ICON,
  STATS_ICON,
  DISCORD_ICON,
  TWITTER_ICON,
  LIMBO_ICON,
  CRASH_ICON,
  LUCKY_SEVENS_ICON,
  DRAGON_ICON,
  SHUTTLE_ICON,
  UpDownIcon,
  LuckyWheelIcon,
} from "../../assets/utilis/icons";
import "./SideBar.css";
import { ethers } from "ethers/lib";
import { prettyValue } from "../../assets/utilis/utilis";

function SideBar({
  isMenuOpen,
  toggleMenu,
  user,
  tokenDecimals
}) {
  usePreventScroll(isMenuOpen);
  const { chainId } = useEthers();
  const { width, height } = useWindowSize();
  const { setCurrencyIconByChainId, setCurrencyTitleByChainId } = useIcons();
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    if (user && user.balance !== undefined) setBalance(user.balance);
  }, [user]);

  function prettyBalanceValue(n, title) {
    n = n ? ethers.utils.parseUnits(n.toFixed(tokenDecimals), tokenDecimals) : ethers.BigNumber.from(0)
    return prettyValue(n, tokenDecimals) + ` ${title}`
  }

  return (
    <nav className={`side-bar ${isMenuOpen ? "side-bar_opened" : ""}`}>
      <div
        className="side-bar__container"
        style={{ height: width <= 1150 && height }}
      >
        <div className="side-bar__main-content">
          <div className="side-bar__logo-block">
            <Link
              to="/"
              className="side-bar__logo"
              onClick={isMenuOpen ? toggleMenu : undefined}
            >
              {LOGO_ICON}
            </Link>
            <button
              className="side-bar__close-button"
              type="button"
              aria-label="Close menu"
              onClick={toggleMenu}
            >
              {CLOSE_ICON}
            </button>
          </div>

          <div className="side-bar__links-container">
            <ul className="side-bar__links-list side-bar__links-list_type_games">
              <li className="side-bar__item">
                <NavLink
                  className={({ isActive }) =>
                    "side-bar__link" +
                    (isActive ? " side-bar__link_selected" : "")
                  }
                  to="/mega-dice"
                  onClick={isMenuOpen ? toggleMenu : undefined}
                >
                  <div className="side-bar__link-icon">{MEGA_DICE_ICON}</div>
                  <p className="side-bar__link-text">Mega Dice</p>
                </NavLink>
              </li>

              <li className="side-bar__item">
                <NavLink
                  className={({ isActive }) =>
                    "side-bar__link" +
                    (isActive ? " side-bar__link_selected" : "")
                  }
                  to="/coin-flip"
                  onClick={isMenuOpen ? toggleMenu : undefined}
                >
                  <div className="side-bar__link-icon">{COIN_FLIP_ICON}</div>
                  <p className="side-bar__link-text">Coin Flip</p>
                </NavLink>
              </li>

              <li className="side-bar__item">
                <NavLink
                  className={({ isActive }) =>
                    "side-bar__link " +
                    (isActive ? " side-bar__link_selected" : "")
                  }
                  to="/limbo"
                  onClick={isMenuOpen ? toggleMenu : undefined}
                >
                  <div className="side-bar__link-icon">{LIMBO_ICON}</div>
                  <p className="side-bar__link-text">Limbo</p>
                </NavLink>
              </li>

              <li className="side-bar__item">
                <NavLink
                  className={({ isActive }) =>
                    "side-bar__link " +
                    (isActive ? " side-bar__link_selected" : "")
                  }
                  to="/crash"
                  onClick={isMenuOpen ? toggleMenu : undefined}
                >
                  <div className="side-bar__link-icon">{CRASH_ICON}</div>
                  <p className="side-bar__link-text">Crash</p>
                </NavLink>
              </li>

              <li className="side-bar__item">
                <NavLink
                  className={({ isActive }) =>
                    "side-bar__link " +
                    (isActive ? " side-bar__link_selected" : "")
                  }
                  to="/lucky-sevens"
                  onClick={isMenuOpen ? toggleMenu : undefined}
                >
                  <div className="side-bar__link-icon">{LUCKY_SEVENS_ICON}</div>
                  <p className="side-bar__link-text">Lucky Sevens</p>
                </NavLink>
              </li>
              <li className="side-bar__item">
                <NavLink
                  className={({ isActive }) =>
                    "side-bar__link" +
                    (isActive ? " side-bar__link_selected" : "")
                  }
                  to="/shuttle"
                  onClick={isMenuOpen ? toggleMenu : undefined}
                >
                  <div className="side-bar__link-icon">{SHUTTLE_ICON}</div>
                  <p className="side-bar__link-text">Shuttle</p>
                </NavLink>
              </li>
              <li className="side-bar__item">
                <NavLink
                  className={({ isActive }) =>
                    "side-bar__link" +
                    (isActive ? " side-bar__link_selected" : "")
                  }
                  to="/lucky-wheel"
                  onClick={isMenuOpen ? toggleMenu : undefined}
                >
                  <div className="side-bar__link-icon">{LuckyWheelIcon}</div>
                  <p className="side-bar__link-text">Lucky wheel</p>
                </NavLink>
              </li>
              <li className="side-bar__item">
                <NavLink
                  className={({ isActive }) =>
                    "side-bar__link side-bar__link_disabled" +
                    (isActive ? " side-bar__link_selected" : "")
                  }
                  to="/dragon"
                  onClick={isMenuOpen ? toggleMenu : undefined}
                >
                  <div className="side-bar__link-icon">{DRAGON_ICON}</div>
                  <p className="side-bar__link-text">Dragon</p>
                </NavLink>
              </li>
              <li className="side-bar__item">
                <NavLink
                  className={({ isActive }) =>
                    "side-bar__link side-bar__link_disabled" +
                    (isActive ? " side-bar__link_selected" : "")
                  }
                  to="/up-or-down"
                  onClick={isMenuOpen ? toggleMenu : undefined}
                >
                  <div className="side-bar__link-icon">{UpDownIcon}</div>
                  <p className="side-bar__link-text">Up or Down</p>
                </NavLink>
              </li>
              {/* <li className="side-bar__item">
                <NavLink
                  className={({ isActive }) =>
                    "side-bar__link side-bar__link_disabled" +
                    (isActive ? " side-bar__link_selected" : "")
                  }
                  to="/roulette"
                  onClick={isMenuOpen ? toggleMenu : undefined}
                >
                  <div className="side-bar__link-icon">{ROULETTE_ICON}</div>
                  <p className="side-bar__link-text">Roulette</p>
                </NavLink>
              </li> */}

            </ul>

            <div className="side-bar__nav-block">
              <p className="side-bar__nav-title">Information</p>
              <ul className="side-bar__links-list">
                <li className="side-bar__item">
                  <a
                    className="side-bar__link"
                    href="https://docs.jellybet.io"
                    target="_blank"
                    rel="noreferrer"
                    onClick={isMenuOpen ? toggleMenu : undefined}
                  >
                    <div className="side-bar__link-icon">{DOCS_ICON}</div>
                    <p className="side-bar__link-text">Docs</p>
                  </a>
                </li>

                {/* <li className="side-bar__item">
                  <NavLink
                    className={({ isActive }) =>
                      "side-bar__link side-bar__link_disabled" +
                      (isActive ? " side-bar__link_selected" : "")
                    }
                    to="/referral"
                    onClick={isMenuOpen ? toggleMenu : undefined}
                  >
                    <div className="side-bar__link-icon">{REF_SYSTEM_ICON}</div>
                    <p className="side-bar__link-text">Referral system</p>
                  </NavLink>
                </li>

                <li className="side-bar__item">
                  <a
                    className="side-bar__link side-bar__link_disabled"
                    href="/#"
                    target="_blank"
                    rel="noreferrer"
                    onClick={isMenuOpen ? toggleMenu : undefined}
                  >
                    <div className="side-bar__link-icon">{STATS_ICON}</div>
                    <p className="side-bar__link-text">Stats</p>
                  </a>
                </li> */}
              </ul>
            </div>

            <div className="side-bar__nav-block side-bar__nav-block_type_network">
              <p className="side-bar__nav-title">Networks</p>
              <ul className="side-bar__links-list">
                <li className="side-bar__item">
                  <a
                    className="side-bar__link side-bar__link_disabled"
                    href="/#"
                    target="_blank"
                    rel="noreferrer"
                    onClick={isMenuOpen ? toggleMenu : undefined}
                  >
                    <div className="side-bar__link-icon">{DISCORD_ICON}</div>
                    <p className="side-bar__link-text">Discord</p>
                  </a>

                  <a
                    className="side-bar__link"
                    href="https://twitter.com/jellybetio"
                    target="_blank"
                    rel="noreferrer"
                    onClick={isMenuOpen ? toggleMenu : undefined}
                  >
                    <div className="side-bar__link-icon">{TWITTER_ICON}</div>
                    <p className="side-bar__link-text">Twitter</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className={`side-bar__info-block ${user ? "side-bar__info-block_user" : ""
            }`}
        >

          {user && chainId ? (
            <div className="side-bar__demo-block">
              <img
                className="side-bar__currency-icon"
                src={setCurrencyIconByChainId(chainId)}
                alt="Network icon"
              />
              <AnimatedNumber
                component="p"
                value={Number(ethers.utils.formatUnits(balance, tokenDecimals))}
                className="side-bar__demo-text"
                duration={200}
                formatValue={(n) => prettyBalanceValue(n, setCurrencyTitleByChainId(chainId))}
              />
            </div>
          ) : null}
        </div>
      </div>
    </nav>
  );
}

export default SideBar;
