import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import {
  DAppProvider,
  // Mumbai,
  Arbitrum,
  Polygon
} from "@usedapp/core";
import "./index.css";
import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";
import ScrollToTop from "./assets/hooks/scrollToTop";
import { connectors } from "./assets/utilis/connectors";


const config = {
  networks: [
    // Mumbai,
    Arbitrum,
    Polygon
  ],
  readOnlyUrls: {
    // [Mumbai.chainId]: "https://rpc.ankr.com/polygon_mumbai",
    [Arbitrum.chainId]: "https://arb1.arbitrum.io/rpc",
    [Polygon.chainId]: "https://rpc.ankr.com/polygon",
  },
  // multicallAddresses: {
  //   [Mumbai.chainId]: null
  // },
  connectors: connectors,
  refresh: 10,
  noMetamaskDeactivate: true
}



const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <ScrollToTop />
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
