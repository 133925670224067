import jellybet from "../../../assets/images/main/jelly-bet-banner.png";
import "./Banner.css";

function Banner() {
  return (
    <div className="banner">
      <div className="banner__title-box">
        <h1 className="banner__title">
          On-chain games
          <br />
          with up to
          <span className="banner__title_type_gradient">&nbsp;X1000</span>
          <br />
          instant returns
        </h1>
        <p className="banner__subtitle">
          Airdrop coming soon
          <span className="banner__subtitle_type_return">$JELLY</span>
        </p>
      </div>

      <img className="banner__image" src={jellybet} alt="Pink jellyfish" />
      <div className="banner__back-ellipse" />
    </div>
  );
}

export default Banner;
