/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import {
  useTokenBalance,
  useEthers,
  useConfig,
  useSendTransaction
} from "@usedapp/core";
import useWindowSize from "../../assets/hooks/useWindowSize";

import { ethers } from "ethers";

import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";
import Main from "../Main/Main";
import MegaDice from "../MegaDice/MegaDice";
import CoinFlip from "../CoinFlip/CoinFlip";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Limbo from "../Limbo/Limbo";
import LuckyWheel from "../LuckyWheel/LuckyWheel";
import Crash from "../Crash/Crash";
import LuckySevens from "../LuckySevens/LuckySevens";
import Shuttle from "../Shuttle/Shuttle";
import WalletModal from "../WalletModal/WalletModal";

import {
  DEFAULT_CHAIN_ID,
  DEFAULT_USDT_ADDRESS,
  DEMO_DECIMALS,
  GAMES_URL,
  IERC20_METADATA_ABI,
  NETWORKS
} from "../../assets/utilis/constants";

function App() {
  const {
    account,
    active,
    chainId,
    library,
    switchNetwork,
    activateBrowserWallet,
    deactivate,
  } = useEthers();
  const { pathname } = useLocation();

  const { readOnlyUrls } = useConfig();

  const [isGamePlaying, setGamePlaying] = useState(false);
  const [isSupportedNetwork, setSupportedNetwork] = useState(false);
  const [preferredChainId, setPreferredChainId] = useState(DEFAULT_CHAIN_ID);
  const [tokenAddress, setTokenAddress] = useState(DEFAULT_USDT_ADDRESS);
  const [tokenDecimals, setTokenDecimals] = useState(DEMO_DECIMALS);
  const [user, setUser] = useState(undefined);

  const overlay = useRef();

  const [isWalletModalOpen, setWalletModalOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isEventListenerActivated, setEventListenerActivated] = useState(false);
  const [isLibraryEventListenerActivated, setLibraryEventListenerActivated] = useState(false);

  const balance = useTokenBalance(tokenAddress, account, {
    refresh: "everyBlock",
    chainId: preferredChainId
  });

  const sendTxFunction = useSendTransaction();
  const txState = sendTxFunction.state;
  const sendTx = sendTxFunction.sendTransaction;
  const [txPayload, setTxPayload] = useState(undefined);
  const [gameHistory, setGameHistory] = useState([]);
  const [checkGameHistory, setCheckGameHistory] = useState(undefined);
  const [isFrameLoaded, setFrameLoaded] = useState(false);
  const [sendToFrameState, setSendToFrameState] = useState(undefined);


  function selectNetwork({ chain_id }) {
    switchNetwork(Number(chain_id))
  }
  
  useEffect(() => {
    if (!isLibraryEventListenerActivated && library) {
      window.addEventListener('message', handleMessageLibrary);
      setLibraryEventListenerActivated(true);
    }
  }, [library])

  useEffect(() => {
    if (!isEventListenerActivated) {
      window.addEventListener('message', handleMessage);
      setEventListenerActivated(true);
    }
  }, [isEventListenerActivated])

  function handleMessageLibrary(event) {
    event.stopPropagation();

    let parser = document.createElement('a');
    parser.href = GAMES_URL;

    if (event.origin === parser.origin) {
      let receivedData = JSON.parse(event.data);
      if (receivedData.type === "signMessage") {
        /// handling request to sign a message
        signAuthMessage(receivedData.payload.message)
          .then(
            (res) => {
              sendToIframe("signMessage", { ...receivedData.payload, signature: res })
            }
          )
          .catch((err) => console.log("sign auth message err:", err));
      } else if (receivedData.type === "sendTx") {
        /// handling request to send tx
        setTxPayload(receivedData.payload)
      } else if (receivedData.type === "switchNetwork") {
        /// handling request to change chainId
        switchNetwork(receivedData.payload.chainId)
      }
      console.log({ data: JSON.parse(event.data) })
    }
  }

  function handleMessage(event) {
    event.stopPropagation();

    let parser = document.createElement('a');
    parser.href = GAMES_URL;

    if (event.origin === parser.origin) {
      let receivedData = JSON.parse(event.data);
      if (receivedData.type === "setGamePlaying") {
        /// handling request to setGamePlaying
        setGamePlaying(receivedData.payload.value)
      } else if (receivedData.type === "newGameHistory") {
        /// handling request to add game to history
        setCheckGameHistory(receivedData.payload);
      } else if (receivedData.type === "requestToWalletConnect") {
        /// handling request to connect wallet btn
        if (!user) {
          openWalletModal();
        } else {
          sendToIframe("editData", { set: { isExternalWalletConnected: true } })
        }
      }
      console.log({ data: JSON.parse(event.data) })
    }
  }

  useEffect(() => {
    if (!checkGameHistory) return;
    if (
      (['luckyWheel'].includes(checkGameHistory.game_type) && ['/lucky-wheel'].includes(pathname)) ||
      (['coinflip'].includes(checkGameHistory.game_type) && ['/lucky-sevens', '/coin-flip'].includes(pathname)) ||
      (['megadice'].includes(checkGameHistory.game_type) && ['/mega-dice'].includes(pathname)) ||
      (['limbo'].includes(checkGameHistory.game_type) && ['/limbo', '/shuttle', '/crash'].includes(pathname))
    ) setGameHistory((prevArr) => [checkGameHistory, ...prevArr]);
  }, [checkGameHistory])

  useEffect(() => {
    if (txPayload) sendTx(txPayload)
  }, [txPayload])

  function sendToIframe(eventType, eventPayload) {
    setSendToFrameState({ eventType, eventPayload });
  }

  useEffect(() => {
    console.log(sendToFrameState)
    if (isFrameLoaded && sendToFrameState) {
      sendToIframeMain(sendToFrameState.eventType, sendToFrameState.eventPayload);
    }
  }, [sendToFrameState])

  useEffect(() => {
    if (isFrameLoaded) {
      /// check chain
      sendToIframeMain("editData", { set: { preferredChainId: preferredChainId, isSupportedNetwork: isSupportedNetwork } });
      /// check account
      if (account) sendToIframeMain("editData", { set: { preferredChainId: preferredChainId, externalAccount: account } });
      /// check isConnected
      /// check chain
      /// check account
      if (account) sendToIframeMain("editData", { set: { preferredChainId: preferredChainId, isExternalWalletConnected: active, isSupportedNetwork: isSupportedNetwork, externalAccount: account } })
      else sendToIframeMain("editData", { set: { preferredChainId: preferredChainId, isExternalWalletConnected: active, isSupportedNetwork: isSupportedNetwork } });
    }
  }, [isFrameLoaded])

  function sendToIframeMain(eventType, eventPayload) {
    let iframe = document.getElementsByClassName("app__iframe");
    if (iframe.length !== 0) {
      iframe[0].contentWindow.postMessage(JSON.stringify({ type: eventType, payload: eventPayload }), GAMES_URL)
    } else {
      console.log('iframe err: page not found')
    }
  }

  useEffect(() => {
    sendToIframe("updateTxStatus", { status: txState.status })
  }, [txState])

  // close side bar on overlay click
  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        setMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  useEffect(() => {
    if (account && active) {
      setUser({ account, balance: ethers.BigNumber.from(0) });
      if (chainId && NETWORKS[`${chainId}`]) {
        sendToIframe("editData", { set: { externalAccount: account, isExternalWalletConnected: true, preferredChainId: Number(chainId), isSupportedNetwork: true } })
      } else {
        sendToIframe("editData", { set: { externalAccount: account, isExternalWalletConnected: true} })
      }
    }
    else if (!account) {
      setUser(undefined);
      sendToIframe("editData", { set: { isExternalWalletConnected: false } })
    };
  }, [active, account, chainId]);

  const updateDecimals = async (tokenAddress) => {
    const signer = new ethers.providers.JsonRpcProvider(readOnlyUrls[preferredChainId]);
    const ctr = new ethers.Contract(tokenAddress, IERC20_METADATA_ABI, signer);
    setTokenDecimals(await ctr.decimals());
  };

  // track network change
  useEffect(() => {
    let preferredChainId_ = preferredChainId;
    if (NETWORKS[`${chainId}`]) {
      preferredChainId_ = Number(chainId);
      setPreferredChainId(Number(chainId));
      setTokenAddress(NETWORKS[`${chainId}`].token_address);
    }
    setSupportedNetwork(chainId === preferredChainId_);
  }, [chainId]);

  useEffect(() => {
    if (!isSupportedNetwork) {
      sendToIframe("editData", { set: { isSupportedNetwork: false } })
    } else {
      sendToIframe("editData", { set: { isSupportedNetwork: true } })
    }
  }, [isSupportedNetwork])

  // track network change
  useEffect(() => {
    updateDecimals(tokenAddress);
  }, [tokenAddress]);

  // listen for balance changes
  useEffect(() => {
    if (balance === undefined || !user) return;

    const newBalance = balance;
    if (isGamePlaying) {
      if (newBalance.lt(user.balance))
        setUser((prevUser) => ({ ...prevUser, balance: newBalance }));
    } else {
      if (!newBalance.eq(user.balance))
        setUser((prevUser) => ({ ...prevUser, balance: newBalance }));
    }

  }, [balance, isGamePlaying]);

  const signAuthMessage = async (message) => {
    if (!library) return;
    const signature = library.getSigner().signMessage(message);
    return signature;
  };

  // logout

  const disconnect = () => {
    deactivate();
    setUser(undefined);
  };

  // toggle side bar state
  const { width } = useWindowSize();

  useEffect(() => {
    if (width > 1150) setMenuOpen(false);
  }, [width]);

  // toggle side bar state
  function toggleMenu() {
    setMenuOpen(!isMenuOpen);
  }

  // toggle wallet modal visibility state
  function openWalletModal() {
    setWalletModalOpen(true);
  }

  function closeWalletModal() {
    setWalletModalOpen(false);
  }

  return (
    <>
      <div className="app">
        <SideBar
          {...{
            isMenuOpen,
            toggleMenu,
            tokenDecimals,
            user
          }}
        />

        <div className="app__content">
          <Header
            onModalOpen={openWalletModal}
            isLoggedIn={!!account}
            {...{
              isMenuOpen,
              toggleMenu,
              disconnect,
              user,
              isGamePlaying,
              tokenDecimals,
              sendToIframe,
              selectNetwork,
            }}
          />

          <Routes>
            <Route
              exact
              path="/"
              element={
                <Main
                />
              }
            />
            <Route
              path="/mega-dice"
              element={
                <MegaDice
                  chainId={preferredChainId}
                  {...{
                    gameHistory,
                    setGameHistory,
                    tokenDecimals,
                    setFrameLoaded
                  }}
                />
              }
            />
            <Route
              path="/coin-flip"
              element={
                <CoinFlip
                  chainId={preferredChainId}
                  {...{
                    gameHistory,
                    setGameHistory,
                    tokenDecimals,
                    setFrameLoaded
                  }}
                />
              }
            />
            <Route
              path="/limbo"
              element={
                <Limbo
                  chainId={preferredChainId}
                  {...{
                    gameHistory,
                    setGameHistory,
                    tokenDecimals,
                    setFrameLoaded
                  }}
                />
              }
            />
            <Route
              path="/crash"
              element={
                <Crash
                  chainId={preferredChainId}
                  {...{
                    gameHistory,
                    setGameHistory,
                    tokenDecimals,
                    setFrameLoaded
                  }}
                />
              }
            />
            <Route
              path="/lucky-sevens"
              element={
                <LuckySevens
                  chainId={preferredChainId}
                  {...{
                    gameHistory,
                    setGameHistory,
                    tokenDecimals,
                    setFrameLoaded
                  }}
                />
              }
            />
            <Route
              path="/shuttle"
              element={
                <Shuttle
                  chainId={preferredChainId}
                  {...{
                    gameHistory,
                    setGameHistory,
                    tokenDecimals,
                    setFrameLoaded
                  }}
                />
              }
            />
            <Route
              path="/lucky-wheel"
              element={
                <LuckyWheel
                  chainId={preferredChainId}
                  {...{
                    gameHistory,
                    setGameHistory,
                    tokenDecimals,
                    setFrameLoaded
                  }}
                />
              }
            />
          </Routes>

          <WalletModal
            isOpen={isWalletModalOpen}
            onClose={closeWalletModal}
            {...{
              activateBrowserWallet,
            }}
          />

          <div
            className={`app__overlay ${isMenuOpen ? "app__overlay_opened" : ""
              }`}
            ref={overlay}
            onTouchStart={() => setMenuOpen(false)}
          />
        </div>
      </div>
    </>
  );
}

export default App;